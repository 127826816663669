import envVariables from "../../utils/config.json";
import axios from "../../axiosInstance";
import { AccountInfo, IPublicClientApplication } from "@azure/msal-browser";
import {  DashboardTilesResponse } from "../../components/SideNavigation/types";
import { AuthenticationResult } from '@azure/msal-browser';


export const PowerBiPermissionScopes: string[] = [
  "https://analysis.windows.net/powerbi/api/Dashboard.Read.All",
  "https://analysis.windows.net/powerbi/api/Dataset.Read.All",
  "https://analysis.windows.net/powerbi/api/Report.Read.All",
  "https://analysis.windows.net/powerbi/api/Workspace.Read.All",
]

export const getPowerbiAccessToken = async(
  instance: IPublicClientApplication,
  accounts: AccountInfo[]
):Promise<string>=>{
  let authResult: AuthenticationResult;
  try {
    // try to acquire access token from MSAL cache first
    authResult = await instance.acquireTokenSilent({ scopes: PowerBiPermissionScopes, account: accounts[0] });
  }
  catch {
    // if access token not available in cache, interact with user to acquire new access token 
    authResult = await instance.acquireTokenPopup({ scopes: PowerBiPermissionScopes, account: accounts[0] });
  }
  // return access token from authnetication result 
  return authResult.accessToken;
}

export const getPowerbiLeadershpiDashboardTiles = async(
  instance: IPublicClientApplication,
  accounts: AccountInfo[]
):Promise<DashboardTilesResponse>=>{
  const accessToken = await getPowerbiAccessToken(instance, accounts);
  const bearer = `Bearer ${accessToken}`;
  var restUrl = `${process.env.REACT_APP_PBI_API_ROOT || envVariables.REACT_APP_PBI_API_ROOT}/groups/
  ${process.env.REACT_APP_PBI_WORKSPACE_ID || envVariables.REACT_APP_PBI_WORKSPACE_ID}/dashboards/
  ${process.env.REACT_APP_PBI_DASHBOARD_ID || envVariables.REACT_APP_PBI_DASHBOARD_ID}/tiles`;
  const response =  await axios({
    method: "GET",
    url: restUrl,
    headers: {
      accept: "application/json;odata.metadata=minimal;",
      "Content-Type": "application/json",
      authorization: bearer,
    },
  });

  return {
    tiles: response.data.value,
    accessToken: accessToken,
    dashboardId: envVariables.REACT_APP_PBI_DASHBOARD_ID,
  }
}